import ContatosView from "@/views/Contato/ContatosView.vue";
import EnsinoPesquisaView from "@/views/EnsinoPesquisaView.vue";
import EstagiosView from "@/views/EstagiosView.vue";
import HomeView from "@/views/HomeView.vue";
import NoticiasView from "@/views/NoticiasView.vue";
import PainelADMView from "@/views/PainelADMView.vue";
import PesquisaSatisfacaoView from "@/views/PesquisaSatisfacaoView.vue";
import PesquisaView from "@/views/PesquisaView.vue";
import PrincipiosViewVue from "@/views/PrincipiosView.vue";
import QuemSomosView from "@/views/QuemSomosView.vue";
import CertificacoesView from "@/views/Transparencia/CertificacoesView.vue";
import CodigoCondutaView from "@/views/Transparencia/CodigoCondutaView.vue";
import ContratosGestaoView from "@/views/Transparencia/ContratosGestaoView.vue";
import LeisDecretosView from "@/views/Transparencia/LeisDecretosView.vue";
import ManuaisView from "@/views/Transparencia/ManuaisView.vue";
import RelatoriosGestaoView from "@/views/Transparencia/RelatoriosGestaoView.vue";
import TransparenciaView from "@/views/TransparenciaView.vue";
import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/quem-somos",
    name: "QuemSomosView",
    component: QuemSomosView,
  },
  ,
  {
    path: "/principios",
    name: "PrincipiosView",
    component: PrincipiosViewVue,
  },
  {
    path: "/noticias",
    name: "NoticiasView",
    component: NoticiasView,
  },
  {
    path: "/transparencia",
    name: "TransparenciaView",
    component: TransparenciaView,
  },
  {
    path: "/ensino-e-pesquisa",
    name: "EninoPesquisaView",
    component: EnsinoPesquisaView,
  },
  {
    path: "/pesquisa",
    name: "PesquisaView",
    component: PesquisaView,
  },
  {
    path: "/estagios",
    name: "EstagiosView",
    component: EstagiosView,
  },
  {
    path: "/contatos",
    name: "ContatosView",
    component: ContatosView,
  },
  {
    path: "/pesquisa_satisfacao",
    name: "PesquisaSatisfacaoView",
    component: PesquisaSatisfacaoView,
  },
];

const subMenusRoutes = [
  // Painel ADM
  {
    path: "/painel-administrativo",
    name: "PainelAdministrativo",
    component: PainelADMView,
  },
  //Transparência
  {
    path: "/leis-decretos",
    name: "LeisDecretos",
    component: LeisDecretosView,
  },
  {
    path: "/certificacoes",
    name: "Certificações",
    component: CertificacoesView,
  },
  {
    path: "/contratos-gestao",
    name: "ContratosGestao",
    component: ContratosGestaoView,
  },
  {
    path: "/relatorios-gestao",
    name: "RelatoriosGestao",
    component: RelatoriosGestaoView,
  },
  {
    path: "/codigo-conduta",
    name: "CodigoConduta",
    component: CodigoCondutaView,
  },
  {
    path: "/manuais",
    name: "Manuais",
    component: ManuaisView,
  },
  // {
  //   path: "/ouvidoria",
  //   name: "Ouvidoria",
  //   component: OuvidoriaView,
  // },
];

for (const route of subMenusRoutes) {
  routes.push(route);
}

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

export default router;
